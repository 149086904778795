/* General container for both desktop and mobile */
.youtube-page {
    position: relative;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    overflow: hidden;
    background: linear-gradient(to bottom, #000000, #000000);
  }
  
  /* Desktop container: Maintain a 16:9 aspect ratio (1920x1080) */
  .youtube-page-desktop {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;

    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  /* Mobile-specific container, maintaining 9:16 aspect ratio (720x1280) */
  .youtube-page-mobile {
    display: none; /* Hide mobile by default */
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;

    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
  }
  
  /* Ensure images fill their respective containers */
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Make sure images cover the div without distorting */
  }

  .youtube-page-desktop iframe {
    position: absolute;
    width: 80%;
    height: 80%;
    object-fit: cover; /* Make sure iframe covers the div without distorting */
  }

  .youtube-page-mobile iframe {
    position: absolute;
    width: 100%;
    height: 75%;
    object-fit: cover; /* Make sure iframe covers the div without distorting */
  }
  
  /* Media query for mobile devices (screen width <= 750px) */
  @media (max-width: 750px) {
    /* Hide the desktop container */
    .youtube-page-desktop {
        display: none;
    }
  
    /* Show the mobile container */
    .youtube-page-mobile {
        display: flex;
    }
  }
  
  /* Media query for desktop devices (screen width > 750px) */
  @media (min-width: 751px) {
    /* Show the desktop container */
    .youtube-page-desktop {
        display: flex;
    }
  
    /* Hide the mobile container */
    .youtube-page-mobile {
        display: none;
    }
  }
  
  /* Adjust the youtube-page-desktop for desktop */
  @media (max-aspect-ratio: 16/9) {
    /* When the viewport is taller than 16:9 */
    .youtube-page-desktop {
        width: 100vw;
        height: calc(100vw * (9 / 16));
    }
  }
  @media (min-aspect-ratio: 16/9) {
    /* When the viewport is wider than 16:9 */
    .youtube-page-desktop {
        width: calc(100vh * (16 / 9));
        height: 100vh;
    }
  }
  
  /* Adjust the youtube-page-mobile for mobile */
  @media (max-aspect-ratio: 9/16) {
    /* When the viewport is taller than 9:16 */
    .youtube-page-mobile {
        width: 100vw;
        height: calc(50vw * (16 / 9));
    }
  }
  @media (min-aspect-ratio: 9/16) {
    /* When the viewport is wider than 9:16 */
    .youtube-page-mobile {
        width: calc(100vh * (9 / 16));
        height: 50vh;
    }
  }
  