/* General container for both desktop and mobile */
.rat-break {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #f49dd4;
  }
  
  /* Desktop container */
  .rat-break-desktop {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  
  /* Mobile container */
  .rat-break-mobile {
    display: none;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 750px) {
    .rat-break-desktop {
      display: none;
    }
    .rat-break-mobile {
      display: flex;
    }
  }
  
  /* Media query for desktop devices */
  @media (min-width: 751px) {
    .rat-break-desktop {
      display: flex;
    }
    .rat-break-mobile {
      display: none;
    }
  }
  
  /* Adjust the rat-break-desktop for desktop */
  @media (max-aspect-ratio: 16/9) {
    .rat-break-desktop {
      width: 100vw;
      height: calc((100vw * (9 / 16)) / 3);
    }
  }
  @media (min-aspect-ratio: 16/9) {
    .rat-break-desktop {
      width: calc(100vh * (16 / 9));
      height: calc(100vh / 3);
    }
  }
  
  /* Adjust the rat-break-mobile for mobile */
  @media (max-aspect-ratio: 9/16) {
    .rat-break-mobile {
      width: 100vw;
      height: calc((100vw * (16 / 9)) / 5);
    }
  }
  @media (min-aspect-ratio: 9/16) {
    .rat-break-mobile {
      width: calc(100vh * (9 / 16));
      height: calc(100vh / 5);
    }
  }

/* Rat image styling */
.rat-image {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the image vertically and horizontally */
    width: auto;
    height: 80%;
  }