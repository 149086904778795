/* General container for both desktop and mobile */
.about-page {
    position: relative;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    overflow: hidden;
    background: linear-gradient(to bottom, #f49dd4, #46298f);
  }
  
  /* Desktop container: Maintain a 16:9 aspect ratio (1920x1080) */
  .about-page-desktop {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  
  .about-page-desktop img.TitleHighlight,
  .about-page-desktop img.TitleText,
  .about-page-desktop img.BodyText,
  .about-page-desktop img.ComicBook {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Mobile-specific container, maintaining 9:16 aspect ratio (720x1280) */
  .about-page-mobile {
    display: none; /* Hide mobile by default */
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  
  /* Media query for mobile devices (screen width <= 750px) */
  @media (max-width: 750px) {
    /* Hide the desktop container */
    .about-page-desktop {
      display: none;
    }
  
    /* Show the mobile container */
    .about-page-mobile {
      display: flex;
    }
  }
  
  /* Media query for desktop devices (screen width > 750px) */
  @media (min-width: 751px) {
    /* Show the desktop container */
    .about-page-desktop {
      display: flex;
    }
  
    /* Hide the mobile container */
    .about-page-mobile {
      display: none;
    }
  }
  
  /* Adjust the about-page-desktop for desktop */
  @media (max-aspect-ratio: 16/9) {
    /* When the viewport is taller than 16:9 */
    .about-page-desktop {
      width: 100vw;
      height: calc(100vw * (9 / 16));
    }
  }
  
  @media (min-aspect-ratio: 16/9) {
    /* When the viewport is wider than 16:9 */
    .about-page-desktop {
      width: calc(100vh * (16 / 9));
      height: 100vh;
    }
  }
  
  /* Adjust the about-page-mobile for mobile */
  @media (max-aspect-ratio: 9/16) {
    /* When the viewport is taller than 9:16 */
    .about-page-mobile {
      width: 100vw;
      height: calc(100vw * (16 / 9));
    }
  }
  
  @media (min-aspect-ratio: 9/16) {
    /* When the viewport is wider than 9:16 */
    .about-page-mobile {
      width: calc(100vh * (9 / 16));
      height: 100vh;
    }
  }

@keyframes hover {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-1%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .ComicBook {
    animation: hover 3s ease-in-out infinite;
  }
  
  /* Carousel Styles */
  .image-carousel {
    position: absolute;
    bottom: 10%; /* Slightly above the bottom of the div */
    left: 5%; /* Adjusted to center the carousel */
    width: 90%; /* 90% of the width of the div */
    height: 30%; /* 30% of the height of the div */
    overflow: hidden;

  }

  .image-carousel-mobile {
    position: absolute;
    bottom: 8%; /* Slightly above the bottom of the div */
    left: 5%; /* Adjusted to center the carousel */
    width: 90%; /* 90% of the width of the div */
    height: 15%; /* 30% of the height of the div */
    overflow: hidden;
  }

  /* Carousel Styles */
  
  .carousel {
    width: 100%;
    height: 100%;
    position: relative;
    
  }

  
  
  .slide-track {
    display: flex;
    width: calc(200%);
    height: 100%;
    animation: scroll 30s linear infinite;
  }
  
  .slide {
    width: calc(100% / 9); /* Adjust according to the number of images */
    flex-shrink: 0;
  }
  
  
  /* Add hover effect to the images in the carousel */
.slide img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition */
  }
  
  .slide img:hover {
    transform: scale(1.1); /* Slightly enlarge the image */
    z-index: 10; /* Bring the hovered image to the front */
  }
  
  /* Keyframes for the scroll animation */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Modal Styles */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dim the rest of the site */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it overlays other content */
  }
  
  .modal img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    cursor: default;
  }
  