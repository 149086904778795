/* General container for both desktop and mobile */
.landing-page {
  position: relative;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  overflow: hidden;
  background: linear-gradient(to bottom, #fae0c9, #f49dd4);
}

/* Desktop container: Maintain a 16:9 aspect ratio (1920x1080) */
.landing-page-desktop {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

/* Mobile-specific container, maintaining 9:16 aspect ratio (720x1280) */
.landing-page-mobile {
  display: none; /* Hide mobile by default */
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

/* Ensure images fill their respective containers */
img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make sure images cover the div without distorting */
}

/* Media query for mobile devices (screen width <= 750px) */
@media (max-width: 750px) {
  /* Hide the desktop container */
  .landing-page-desktop {
      display: none;
  }

  /* Show the mobile container */
  .landing-page-mobile {
      display: flex;
  }
}

/* Media query for desktop devices (screen width > 750px) */
@media (min-width: 751px) {
  /* Show the desktop container */
  .landing-page-desktop {
      display: flex;
  }

  /* Hide the mobile container */
  .landing-page-mobile {
      display: none;
  }
}

/* Adjust the landing-page-desktop for desktop */
@media (max-aspect-ratio: 16/9) {
  /* When the viewport is taller than 16:9 */
  .landing-page-desktop {
      width: 100vw;
      height: calc(100vw * (9 / 16));
  }
}
@media (min-aspect-ratio: 16/9) {
  /* When the viewport is wider than 16:9 */
  .landing-page-desktop {
      width: calc(100vh * (16 / 9));
      height: 100vh;
  }
}

/* Adjust the landing-page-mobile for mobile */
@media (max-aspect-ratio: 9/16) {
  /* When the viewport is taller than 9:16 */
  .landing-page-mobile {
      width: 100vw;
      height: calc(100vw * (16 / 9));
  }
}
@media (min-aspect-ratio: 9/16) {
  /* When the viewport is wider than 9:16 */
  .landing-page-mobile {
      width: calc(100vh * (9 / 16));
      height: 100vh;
  }
}

/* Keyframe animations */

@keyframes hover {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDownButton {
  from {
    transform: translateY(-1000%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* City elements slide up from bottom */
.city {
  transform: translateY(100%);
  animation: slideUp 1s ease-out forwards, hover 5s ease-in-out 1s infinite;
}

/* Title elements slide down from top */
.title {
  filter: drop-shadow(0 0.5rem 0.25rem gray);

  transform: translateY(-100%);
  animation: slideDown 1s ease-out forwards;
}

/* MLH Banner slides down from top after a delay */
.mlhBanner {
  filter: drop-shadow(0 0.5rem 0.25rem gray);

  transform: translateY(-100%);
  animation: slideDown 1s ease-in-out .5s forwards;
}

/* ScissorTail logo slides in from left after a delay */
.logo {
  filter: drop-shadow(0 0.5rem 0.25rem gray);

  transform: translateX(-100%);
  animation: slideInLeft 1s ease-in-out .5s forwards, hover 3s ease-in-out 1.5s infinite;
}

/* Subtitle fades in after all animations */
.subtitle {
  opacity: 0;
  animation: fadeIn 1s ease-out 1s forwards;
}

.apply-button {
  position: relative;
  width: 20%;
  left: 30%;
  height: 10.5%;
  top: 50%;

  transform: translateY(-1000%);
  animation: slideDownButton 1s ease-out 1.5s forwards;

  filter: drop-shadow(0 0.5rem 0.25rem gray);
}

.apply-button-mobile {
  position: relative;
  width: 48%;
  height: 8%;
  top: 45%;
  right: -3%;

  transform: translateY(-1000%);
  animation: slideDownButton 1s ease-out 1.5s forwards;

  filter: drop-shadow(0 0.5rem 0.25rem gray);
}