/* General container for both desktop and mobile */
.live-page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* background: linear-gradient(to bottom, #fae0c9, #f49dd4); */
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
    background-image: url(../assets/hacklahoma_water_backgroundsquare.png);
}

/* IDK but Lucas threw this in */
.live-page h1 {
    font: italic bold 32px "Nunito", serif;
    color: #ffffff;
}

/* -------------------------------
     Desktop Layout (screens > 750px)
  ---------------------------------- */
.live-page-desktop {
    width: 100%;
    display: none;
    /* Hidden by default */
    padding-left: 3vw;
    padding-right: 3vw;
}

.desktop-container {
    display: flex;
    flex-direction: row;
}

/* Schedule and Announcements Sections */
.schedule-section,
.announcements-section {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
}

/* Schedule Section: fixed header with scrollable list */
.schedule-section {
    display: flex;
    flex-direction: column;
}

.schedule-list-container {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
}

/* Schedule List Styles */
.schedule-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.schedule-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(172, 252, 255);
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.schedule-item:hover {
    background: #f0f0f0;
}

/* Arrange schedule item elements:
     - Time on the left,
     - Title in the center,
     - Location on the right */
.schedule-item-time {
    width: 80px;
    font-weight: bold;
}

.schedule-item-title {
    flex: 1;
    margin: 0 10px;
    text-align: left;
    font-family: "Nunito", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.schedule-item-location {
    width: 80px;
    text-align: right;
}

/* Announcements Section Styles */
.announcements-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.announcement-item {
    background: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.announcement-item:hover {
    background: #f0f0f0;
}

.announcement-item-time {
    font-weight: bold;
}

.announcement-item-title {
    margin-left: 10px;
}

/* -------------------------------
     Mobile Layout (screens ≤ 750px)
  ---------------------------------- */
.live-page-mobile {
    width: 100%;
    display: none;
    /* Hidden by default */
    flex-direction: column;
}

/* In mobile layout, the schedule section is structured the same */
.live-page-mobile .schedule-section {
    display: flex;
    flex-direction: column;
}

.live-page-mobile .schedule-list-container {
    flex: 1;
    overflow-y: auto;
    margin-top: 10px;
}

/* -------------------------------
           Modal Styles
  ---------------------------------- */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

/* -------------------------------
     Conditional Visibility via Media Queries
  ---------------------------------- */
@media (min-width: 751px) {
    .live-page-desktop {
        display: block;
    }

    .live-page-mobile {
        display: none;
    }
}

@media (max-width: 750px) {
    .live-page-desktop {
        display: none;
    }

    .live-page-mobile {
        display: flex;
    }
}