/* General container for both desktop and mobile */
.sponsor-page {
    position: relative;
    display: flex;
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
    overflow: hidden;
    background: black;
}

/* Desktop container: Maintain a 16:9 aspect ratio (1920x1080) */
.sponsor-page-desktop {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

/* Mobile-specific container, maintaining 9:16 aspect ratio (720x1280) */
.sponsor-page-mobile {
    display: none;
    /* Hide mobile by default */
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.logo-grid-container {
    position: relative;

    margin: auto;

    display: flex;
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
    height: 60%;
    width: 100%;
    /* Ensure the grid spans the full width of the container */
}

.logo-grid-container img {
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth scaling and optional shadow effect */
}

.logo-grid-container img:hover {
    transform: scale(1.05); /* Scale the image slightly */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow to enhance the effect */
}

.logo-grid-desktop {
    display: grid;

    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto;

    /* Space between images */
    gap: 5%;

    /* Adjusts vertical positioning of the grid */
    height: 75%;

    /* Ensure the grid spans the full width of the container */
    width: 90%;
}

.logo-grid-mobile {
    display: grid;

    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto auto;

    /* Space between images */
    gap: 5%;

    /* Adjusts vertical positioning of the grid */
    height: 100%;

    /* Ensure the grid spans the full width of the container */
    width: 95%;

    margin-bottom: 0px;
}

.logo-grid-desktop a {
    display: flex;
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
}

.logo-grid-mobile a {
    display: flex;
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
}

.logo-grid-desktop img {
    position: relative;
    height: auto;
    padding: auto;
    object-fit: contain;
    /* Ensures logos fit within their space */
    margin: auto;
}

.logo-grid-mobile img {
    position: relative;
    height: 70%;
    padding: auto;
    object-fit: contain;
    /* Ensures logos fit within their space */
    margin: auto;
}

/* Adjustments for Sponsor1 on mobile */
@media (max-width: 750px) {
    .sponsor-page-desktop {
        display: none;
        /* Hide desktop container on mobile */
    }

    .sponsor-page-mobile {
        display: flex;
        /* Ensure mobile container is visible */
    }
}

/* Desktop-specific styles */
@media (min-width: 751px) {
    .sponsor-page-desktop {
        display: flex;
    }

    .sponsor-page-mobile {
        display: none;
        /* Hide mobile container */
    }
}

/* Aspect ratio adjustments for desktop */
@media (max-aspect-ratio: 16/9) {
    .sponsor-page-desktop {
        width: 100vw;
        height: calc(100vw * (9 / 16));
    }
}

@media (min-aspect-ratio: 16/9) {
    .sponsor-page-desktop {
        width: calc(100vh * (16 / 9));
        height: 100vh;
    }
}

/* Aspect ratio adjustments for mobile */
@media (max-aspect-ratio: 9/16) {
    .sponsor-page-mobile {
        width: 100vw;
        height: calc(100vw * (16 / 9));
    }
}

@media (min-aspect-ratio: 9/16) {
    .sponsor-page-mobile {
        width: calc(100vh * (9 / 16));
        height: 100vh;
    }
}