/* Import the Comic Neue font */
/*@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

/* General container for both desktop and mobile */
.faq-page {
    position: relative;
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center;     /* Center content vertically */
    overflow: hidden;
    background: linear-gradient(to bottom, #46298f, #000000);
}

/* Desktop container: Maintain a 16:9 aspect ratio (1920x1080) */
.faq-page-desktop {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

/* Mobile-specific container, maintaining 9:16 aspect ratio (720x1280) */
.faq-page-mobile {
    display: none; /* Hide mobile by default */
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Make sure images cover the div without distorting */
  }

/* Media query for mobile devices (screen width <= 750px) */
@media (max-width: 750px) {
    /* Hide the desktop container */
    .faq-page-desktop {
      display: none;
    }
  
    /* Show the mobile container */
    .faq-page-mobile {
      display: flex;
    }
  }
  
  /* Media query for desktop devices (screen width > 750px) */
  @media (min-width: 751px) {
    /* Show the desktop container */
    .faq-page-desktop {
      display: flex;
    }
  
    /* Hide the mobile container */
    .faq-page-mobile {
      display: none;
    }
  }
  
  /* Adjust the about-page-desktop for desktop */
  @media (max-aspect-ratio: 16/9) {
    /* When the viewport is taller than 16:9 */
    .faq-page-desktop {
      width: 100vw;
      height: calc(100vw * (9 / 16));
    }
  }
  
  @media (min-aspect-ratio: 16/9) {
    /* When the viewport is wider than 16:9 */
    .faq-page-desktop {
      width: calc(100vh * (16 / 9));
      height: 100vh;
    }
  }
  
  /* Adjust the about-page-mobile for mobile */
  @media (max-aspect-ratio: 9/16) {
    /* When the viewport is taller than 9:16 */
    .faq-page-mobile {
      width: 100vw;
      height: calc(100vw * (16 / 9));
    }
  }
  
  @media (min-aspect-ratio: 9/16) {
    /* When the viewport is wider than 9:16 */
    .faq-page-mobile {
      width: calc(100vh * (9 / 16));
      height: 100vh;
    }
  }


/*Animation for the FAQ page*/
  @keyframes hover {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-1%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .stars {
    filter: drop-shadow(0 0.5rem 0.25rem rgb(42, 41, 41));
    transform: translateY(50%);
    animation: hover 5s ease-in-out 1s infinite;
  }

/* FAQ DESKTOP */

.faq-container {
    position: relative;
    width: 60%;
    height: 80%;
    top: 10%;
    left: 35%;

    display: grid;
    grid-template-rows: repeat(6, auto);   /* 6 rows */
    box-sizing: border-box;                /* Include border in size calculations */
}

/* Styles for each row */
.faq-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%; /* Vertical spacing between rows */
}

/* Shift every other row to the left by 25% */
.row-shifted {
    transform: translateX(-20%);
}

/* Styles for each faq-section */
.faq-section {
    width: 48%; /* Leave space between buttons */
    border: 0.4vh solid black; /* Thin outline */
    border-radius: 1.5vh; /* Curved corners */
    box-sizing: border-box;
    box-shadow: 0.5vh 0.5vh 0.5vh rgba(0,0,0,0.3); /* Slight drop shadow */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text within each button */
    padding: 2%; /* Percentage-based padding */
    font-size: min(3cqh, 1.5cqmax); /* Responsive text size */
    transition: transform 0.2s, box-shadow 0.2s; /* Hover effects */
    cursor: pointer;
    font-family: 'Nunito', serif; /* Apply comic font */
    font-weight: 800;
}

/* Hover effect for each faq-section */
.faq-section:hover {
    transform: scale(1.05); /* Grow slightly larger */
    box-shadow: 1vh 1vh 1vh rgba(0,0,0,0.3); /* Enhanced shadow on hover */
}

/* FAQ MOBILE */

.faq-container-mobile {
  position: relative;
  width: 70%;
  height: 70%;
  top: 25%;
  left: 20%;

  display: grid;
  grid-template-rows: repeat(6, auto);   /* 6 rows */
  box-sizing: border-box;                /* Include border in size calculations */
}

/* Styles for each row */
.faq-row-mobile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%; /* Vertical spacing between rows */
}

/* Shift every other row to the left by 25% */
.row-shifted-mobile {
  transform: translateX(-15%);
}

/* Styles for each faq-section */
.faq-section-mobile {
    width: 48%; /* Leave space between buttons */
    border: 0.4vh solid black; /* Thin outline */
    border-radius: 1.5vh; /* Curved corners */
    box-sizing: border-box;
    box-shadow: 0.5vh 0.5vh 0.5vh rgba(0,0,0,0.3); /* Slight drop shadow */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text within each button */
    padding: 2%; /* Percentage-based padding */
    font-size: min(4cqh, 2cqmax); /* Responsive text size */
    transition: transform 0.2s, box-shadow 0.2s; /* Hover effects */
    cursor: pointer;
    font-family: 'Nunito', serif; /* Apply comic font */
    font-weight: 800;
}

/* Hover effect for each faq-section */
.faq-section-mobile:hover {
  transform: scale(1.05); /* Grow slightly larger */
  box-shadow: 1vh 1vh 1vh rgba(0,0,0,0.3); /* Enhanced shadow on hover */
}
  
/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.modal-content {
    background-color: white;
    border: 0.5vh solid black; /* Similar border to the question's box */
    padding: 2%;
    width: 80%;
    max-width: 500px;
    position: relative;
    font-family: 'Nunito', serif; /* Same font as questions */
    font-weight: 800;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0.5vh 0.5vh 0.5vh rgba(0,0,0,0.3); /* Slight drop shadow */
}

/* Modal close button */
.modal-close {
    position: absolute;
    top: 1vh;
    right: 1.5vh;
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    font-family: 'Nunito', serif;
    font-weight: 800;
    color: black;
}

/* MOBILE - Modal close button */
.modal-close-mobile {
    position: absolute;
    top: 0.1vh; /* Adjust top positioning for mobile */
    right: 0.1vh; /* Adjust right positioning for mobile */
    background: none;
    border: none;
    font-size: 1em;
    cursor: pointer;
    font-family: 'Nunito', serif;
    font-weight: 800;
    color: black;
}

/* Modal text */
.modal-text {
    font-size: calc(0.8em + 0.5vw); /* Responsive text size */
    margin-top: 2%;
    font-family: 'Nunito', serif;
    font-weight: 800;
}
